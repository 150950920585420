import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./WeeksInput.module.scss";
import WeeksUntilNavBar from "../home/WeeksUntilNavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import moment from "moment";

class WeeksUntil extends Component {
  constructor() {
    super();
    this.state = {
      dateString: "2025-12-25",
      weeksLeft: 1,
      humanRep: "December 25, 2025",
    };
  }

  componentDidMount() {
    const { dateString } = this.state;
    let humanRep = moment.utc(dateString).format("MMMM D, YYYY");

    let now = moment().utc();
    let weeksLeft = moment.utc(dateString).diff(now, "weeks");

    this.setState({ weeksLeft, humanRep });
  }

  copyLink() {
    /* Get the text field */
    var copyText = document.getElementById("shareLink");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

  renderWeeksUntil() {
    const { humanRep } = this.state;
    return (
      <div className={styles.enterDateContainer}>
        <div className={styles.entryContent}>
          <div className={styles.header}>There are</div>
          <div className={styles.weeksNumer}>
            {this.state.weeksLeft.toLocaleString("en")}
          </div>
          <div className={styles.subHeader}>weeks until Christmas!</div>
          <div className={styles.subHeader}> {humanRep}</div>
          <div className={styles.helpText}>
            This page will recalcalculate with every visit, feel free to
            bookmark or share the link below.
          </div>
          <div>
            <input
              className={styles.copyLinkInput}
              value={window.location}
              id="shareLink"
              readOnly
              onClick={() => this.copyLink()}
            ></input>
            <div className={styles.copyButton} onClick={() => this.copyLink()}>
              Copy Link
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>How many weeks until Christmas</title>
        <meta
          name="description"
          content="Calculate how many more weeks until Christmas"
        />
        <meta
          name="keywords"
          content="christmas, weeks until, weeks till, weeks til, calculate weeks"
        />
        <meta
          property="og:url"
          content="https://www.failflow.com/weeksuntil/christmas"
        />
        <meta property="og:title" content="How many weeks until Christmas" />
        <meta
          property="og:description"
          content="Calculate how many more weeks until until Christmas"
        />
        <meta property="og:image" content="https://i.imgur.com/HmyUCpi.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/HmyUCpi.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/HmyUCpi.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <WeeksUntilNavBar />
        {this.renderWeeksUntil()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(WeeksUntil);
