import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./WeeksInput.module.scss";
import WeeksUntilNavBar from "../home/WeeksUntilNavBar";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";

class WeeksInput extends Component {
  constructor() {
    super();
    this.state = {
      dateString: "2025-12-25",
    };
  }

  setDate() {
    const { dateString } = this.state;
    this.props.history.push("/weeksuntil/" + dateString);
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>How many weeks until</title>
        <meta
          name="description"
          content="Calculate how many more weeks until a certain date"
        />
        <meta
          name="keywords"
          content="weeks until, weeks till, weeks til, calculate weeks"
        />
        <meta property="og:url" content="https://www.failflow.com/weeksuntil" />
        <meta property="og:title" content="How many weeks until?" />
        <meta
          property="og:description"
          content="Calculate how many more weeks until a certain date"
        />
        <meta property="og:image" content="https://i.imgur.com/HmyUCpi.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/HmyUCpi.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/HmyUCpi.png"
        />
      </Helmet>
    );
  }

  handleInputChange(e) {
    this.setState({ dateString: e.target.value });
  }

  renderWeeksEntry() {
    let inputClass = styles.weekInput;
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (iOS) {
      inputClass = styles.weekInputiOS;
    }

    return (
      <div className={styles.enterDateContainer}>
        <div className={styles.entryContent}>
          <div className={styles.holidayContainer}>
            <a href="/weeksuntil/endofyear">
              <div className={styles.holiday}>Weeks until End of the Year?</div>
            </a>
            <a href="/weeksuntil/christmas">
              <div className={styles.holiday}>Weeks until Christmas?</div>
            </a>
            <a href="/weeksuntil/thanksgiving">
              <div className={styles.holiday}>Weeks until Thanksgiving?</div>
            </a>
          </div>
          <div className={styles.entryHeader}>How many weeks until</div>
          <div className={styles.entryHelpText}>Enter a date</div>
          {!iOS ? (
            <input
              type="date"
              name="startDate"
              className={inputClass}
              pattern="^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$"
              title="Enter a date in the format MM-DD-YYYY i.e. 12-25-2024"
              tabIndex="0"
              required
              value={this.state.dateString}
              onChange={(e) => this.handleInputChange(e)}
            />
          ) : (
            <div>
              <input
                type="date"
                name="startDate"
                className={inputClass}
                pattern="^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$"
                title="Enter a date in the format MM-DD-YYYY i.e. 12-25-2024"
                tabIndex="0"
                required
                value={this.state.dateString}
                onChange={(e) => this.handleInputChange(e)}
              />
            </div>
          )}

          <div className={styles.enterButton} onClick={() => this.setDate()}>
            DONE
          </div>
          <div className={styles.homeHelpText}>
            Need to calculate how many weeks until a certain date? Pick a date
            above and click done to see how many weeks until that date on a page
            that you can bookmark and will recalculate on any visit.
          </div>
          <div>
            <a href="/weeksuntil/3-weeks-from-today">
              <div className={styles.weeksFromTodayLink}>
                What day is 3 weeks from today?
              </div>
            </a>
            <a href="/weeksuntil/2-weeks-from-today">
              <div className={styles.weeksFromTodayLink}>
                What day is 2 weeks from today?
              </div>
            </a>
            <a href="/weeksuntil/1-week-from-today">
              <div className={styles.weeksFromTodayLink}>
                What day is a week from today?
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <WeeksUntilNavBar />
        {this.renderWeeksEntry()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(WeeksInput);
