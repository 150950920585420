import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToast } from "../../../actions/toasts";
import { Helmet } from "react-helmet";
import NewSignIn from "./NewSignIn";
import "./cool.css"

const mapStateToProps = (state) => ({
  fullState: state,
  todos: state.todos,
  user: state.user,
  showToast: state.toast.show,
  toastMessage: state.toast.message,
});

const mapDispatchToProps = (dispatch) =>
  // okay we need to put the token in the state
  // so we can send it to authorized responses
  bindActionCreators({ showToast }, dispatch);

class SignUp extends Component {
  goTo = (route) => {
    this.props.history.push(route);
  };
  constructor() {
    super();
    this.state = {
      modalUp: false,
      emailEntered: "",
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user !== this.props.user
    ) {
      this.goTo('/dashboard')
    }
  }

  BackgroundIllustration(props) {
    return (
      <svg
        viewBox="0 0 1090 1090"
        aria-hidden="true"
        fill="none"
        preserveAspectRatio="none"
        {...props}
      >
        <circle cx={545} cy={545} r="544.5" />
        <circle cx={545} cy={545} r="480.5" />
        <circle cx={545} cy={545} r="416.5" />
        <circle cx={545} cy={545} r="352.5" />
      </svg>
    )
  }


  render() {
    return (
      <div className="bg-gray-100 h-screen">
       <Helmet>
          <title>FailFlow - You're going to die.  Make the most of it.</title>
          <meta
            name="description"
            content="Daily activities and reminders to be more mindful.  Create a private gratitude journal and use mortality reminders."
          />
          <meta
            name="keywords"
            content="failflow, weeks until you die, gratitude journal"
          />
          <meta property="og:url" content="https://www.failflow.com" />
          <meta property="og:title" content="FailFlow" />
          <meta
            property="og:description"
            content="Daily activities and reminders to be more mindful.  Create a private gratitude journal and use mortality reminders."
          />
          <meta property="og:image" content="https://i.imgur.com/JRRqRyi.png" />
          <meta
            property="og:image:url"
            content="https://i.imgur.com/JRRqRyi.png"
          />
          <meta
            property="twitter:image"
            content="https://i.imgur.com/JRRqRyi.png"
          />
        </Helmet>

        <main className="flex min-h-full overflow-hidden pt-16 sm:py-28" id="mesh">
          <div className="mx-auto flex w-full max-w-xl flex-col px-4 sm:px-6 h-fit">
            <div className="rounded-xl mt-10 mx-2 flex-auto bg-white py-10 px-4 shadow-2xl shadow-gray-900/10 sm:mx-0 sm:flex-none sm:rounded-5xl sm:p-12">
              <a href="/" aria-label="Home">
                <img
                  className="mx-auto mb-10 h-6 w-auto sm:h-6"
                  src="https://i.imgur.com/PAusHyM.jpeg"
                  alt=""
                />
              </a>
              <div className="relative mt-12 sm:mt-16">

                <h1 className="text-center text-2xl font-medium tracking-tight text-gray-900">
                  Sign in to continue
                </h1>
                {"subtitle" && (
                  <p className="mt-3 text-center text-lg text-gray-600"> Use a Google account to get started</p>
                )}
              </div>
              <div className="w-fit mx-auto mt-5 pb-10">
                <NewSignIn></NewSignIn>
              </div>
              <a href="mailto:shah@failflow.com">
                <p className="mt-3 text-center text-md  text-gray-600">Issues signing in?</p>
              </a>
            </div>
            <a href="/"> <div className="mt-5 ml-5 font-semibold text-white text-sm hover:opacity-90">
              <p>© FailFlow 2025</p>
            </div>
            </a>

          </div>
        </main>

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
